export const environment = {
  local: false,
  sit: true,
  uat: false,
  prod: false,
  systemEndpoint: 'https://canary.autoassistcare.etiqa.com.my/api',
  systemEndpointV2: 'https://canary.autoassistcare.etiqa.com.my/api/v2',
  commonEndpoint: 'https://canary.autoassistcare.etiqa.com.my/common/v1/',
  socketEndpoint: 'https://canary.autoassistcare.etiqa.com.my',
  cmsEndPoint: 'https://canary.autoassistcare.etiqa.com.my/cms',
  webEndpoint: 'https://canary.autoassistcare.etiqa.com.my',
  dashboardEndpoint: 'https://canary.autoassistcare.etiqa.com.my',
  dashboardEndpointV2: 'https://canary.autoassistcare.etiqa.com.my/dashboard-v2',
  partnerEndpointV2: 'https://canary.autoassistcare.etiqa.com.my/partner-v2',
  authEndpoint: 'https://canary.autoassistcare.etiqa.com.my/auth',
  minioEndpoint: 'https://canary.autoassistcare.etiqa.com.my/minio',
  providerEndpoint: 'https://canary.autoassistcare.etiqa.com.my/provider',
  recaptchaSiteKey: '6LdmwIIaAAAAAFru89LUjA4BpJCDl9bmvp6kLgdA',
  smsTemplate: 'Having trouble contacting Etiqa Auto Assist Care? You may submit your towing/breakdown assistance at',
  loadPermission: true,
  firebase: {
    apiKey: 'AIzaSyBrz3dPOx4zmntVFfTiLtN8P6ZsK94uGB4',
    authDomain: 'etiqa-all-things-auto.firebaseapp.com',
    projectId: 'etiqa-all-things-auto',
    storageBucket: 'etiqa-all-things-auto.appspot.com',
    messagingSenderId: '938302633829',
    appId: '1:938302633829:web:0052bf19f027d27897538c',
    measurementId: 'G-2LDKTVGT7J',
  },
};
